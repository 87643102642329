<template>
  <hcc-modal
    :title="$t('reports.modal-new-title')"
    name="report"
    :width="600"
    @confirm="createReport"
    @closed="close"
    @cancel="close"
    @opened="buildModal"
    :resizable="true"
    :confirmDisableButton="!isReportEnabled"
  >
    <div class="reports">
      <hcc-select
        customClass="select"
        :label="$t('reports.report-type')"
        v-model="type"
        :options="optionReportType"
         option-label="name"
      />
      <span class="reports__information">{{ type.information }}</span>
      <template v-if="isScheduledReport">
        <hcc-select v-if="notClient"
          customClass="select"
          :label="$t('reports.interval')"
          v-model="timeInterval"
          :options="intervalType"
          option-label="name"
        />
        <template v-if="showDay">
          <label class="reports__time">Seleccione dia</label>
          <date-picker
            class="picker"
            format="DD-MM-YYYY"
            type="date"
            :disabled-date="notBeforeToday"
            v-model="scheduleDay"
            placeholder="Select date"
          />
        </template>
        <hcc-select
          customClass="select"
          :label="$t('reports.current-time')"
          v-model="timeToGenerate"
          :options="time"
          option-label="name"
        />
      </template>
      <template v-if="!isScheduledReport">
        <label class="reports__time" v-if="notClient">
          {{ $t('reports.range') }}</label>
        <input-date ref="schedule"
          v-if="notClient"
          class="select"
          :options="{ opens: 'right' }"
          :startDate="currentStartDate"
          :endDate="currentEndDate"
          @update="dateChange"/>
      </template>
      <hcc-select
        customClass="select"
        :label="$t('reports.type')"
        v-model="reportType"
        :options="reportTypesAvailables"
        option-label="lang"
      />
      <hcc-select v-if="isOwner"
        customClass="select"
        :label="$t('app.company')"
        v-model="company"
        :options="companies"
        option-label="name"
      />
      <hcc-select
        customClass="select"
        :label="$t('app.campaigns')"
        :multiple="true"
        v-model="campaign"
        :options="currentCampaigns"
        option-label="name"
        track-by="id"
        :allow-empty="true"
        :close-on-select="true"
        :limit="1"
      />
      <hcc-select v-if="isScheduledReport"
        customClass="select"
        :label="$t('reports.action')"
        v-model="action"
        :options="actions"
        option-label="name"
      />
      <label v-show="isScheduledReport" class="reports__label">
        {{ $t('reports.action-label') }}
      </label>
      <hcc-input v-if="emailAction" label="Email" v-model.trim="email" />
    </div>
  </hcc-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DatePicker from 'vue2-datepicker';
import { hours } from '@/utils/helper';

export default {
  props: {
    params: {
      type: Object,
    },
  },
  components: {
    DatePicker,
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    InputDate: () => import('@/components/InputDate.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
  },
  data() {
    return {
      email: null,
      timeInterval: null,
      timeToGenerate: null,
      reportType: null,
      company: null,
      campaign: [],
      action: null,
      type: {},
      currentStartDate: null,
      currentEndDate: null,
      scheduleDay: null,
      showDay: false,
      time: hours(),
    };
  },
  computed: {
    ...mapState({
      lang: state => state.shared.lang,
      types: state => state.reports.types,
    }),
    ...mapGetters({
      isOwner: 'shared/isOwner',
      companies: 'shared/getCompanies',
    }),
    optionReportType() {
      return [{
        name: this.$t('reports.generate'),
        information: this.$t('reports.type-generate'),
      }, {
        name: this.$t('reports.program'),
        information: this.$t('reports.type-scheduled'),
      }];
    },
    isScheduledReport() {
      return this.type.name === this.$t('reports.program');
    },
    userCompany() {
      return JSON.parse(localStorage.currentCompany);
    },
    currentCompanyId() {
      return this.isOwner ? this.company.id : this.userCompany.id;
    },
    currentCampaigns() {
      const { campaigns = [] } = this.companies.find(({ id }) => id === this.currentCompanyId);
      return campaigns;
    },
    isReportEnabled() {
      if (this.isScheduledReport) {
        return !!this.timeInterval && !!this.timeToGenerate
          && (!this.showDay || !!this.scheduleDay) && !!this.reportType;
      }
      return (!!this.currentStartDate && !!this.currentEndDate && !!this.reportType)
        || (!!this.reportType && this.reportType?.key === 'ClientesReport');
    },
    intervalType() {
      return [
        { id: 'daily', name: this.$t('reports.daily') },
        { id: 'weekly', name: this.$t('reports.weekly') },
        { id: 'biweekly', name: this.$t('reports.fortnightly') },
        { id: 'monthly', name: this.$t('reports.monthly') },
      ];
    },
    actions() {
      return [
        { id: 1, name: this.$t('reports.send-action') },
        { id: 2, name: this.$t('reports.save-action') },
      ];
    },
    emailAction() {
      return this.action?.id === 1;
    },
    reportTypesAvailables() {
      return this.types.map(type => ({
        ...type,
        lang: this.lang === 'es' ? type.es : type.en,
      })).filter(({ key }) => key !== 'ConversationClientReport');
    },
    notClient() {
      return this.reportType?.key !== 'ConversationClientReport' || this.reportType === null;
    },
  },
  created() {
    this.company = this.userCompany;
  },
  watch: {
    company() {
      this.campaign = [];
    },
    timeInterval(value) {
      if (value) {
        this.showDay = value.name === this.$t('reports.weekly')
          || value.name === this.$t('reports.monthly');
      } else {
        this.showDay = false;
      }
    },
  },
  methods: {
    dateChange(startDate, endDate) {
      this.currentStartDate = startDate;
      this.currentEndDate = endDate;
    },
    clear() {
      this.email = null;
      this.timeInterval = null;
      this.reportType = null;
      this.campaign = [];
      this.action = null;
      this.currentStartDate = null;
      this.currentEndDate = null;
      this.timeToGenerate = null;
      this.scheduleDay = null;
      this.showDay = false;
    },
    close() {
      this.clear();
      this.$emit('close');
    },
    createReport() {
      if (this.isScheduledReport) {
        const day = this.timeInterval.id === 'weekly' ? this.$moment(this.scheduleDay).isoWeekday()
          : this.$moment(this.scheduleDay).format('D');

        const data = {
          day: `${day}`,
          hour: this.timeToGenerate.id,
          frequency: this.timeInterval.id,
          company: this.currentCompanyId,
          campaign: this.campaign?.map(({ id }) => id),
          type: this.reportType.key,
          email: this.email,
        };
        this.$emit('schedule', data);
      } else {
        const data = {
          start: this.currentStartDate,
          end: this.currentEndDate,
          company: this.currentCompanyId,
          campaign: this.campaign?.map(({ id }) => id),
          type: this.reportType.key,
        };
        this.$emit('generate', data);
      }
      this.clear();
    },
    buildModal() {
      if (this.params?.companyId) {
        this.reportType = this.reportTypesAvailables.find(({ key }) => key === this.params.type);
        this.company = this.companies.find(({ id }) => this.params.companyId === id);
      }
      [this.type] = this.optionReportType;
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
};

</script>

<style scoped lang="scss">
 @import '~styles/components/_generate-report.scss';
</style>
